import React from 'react'
import Navbar from '../components/Navbar'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function About() {
    return (
        <div>
            <div className="fixed z-50 flex w-screen mb-10 bg-white flex-nowrap drop-shadow-lg backdrop-blur-sm dark:bg-black">
                <Navbar />
            </div>
            <div className='min-h-screen py-20 '>
                <div className='flex items-center justify-center py-5'>
                    <img className='' src="gememiom_top.png"/>
                </div>
                <div className='flex flex-col min-h-screen gap-4'>
                    <h1 className='my-2 text-2xl font-bold text-center lg:my-10'>About GeMemiOM</h1>
                    <div className='border border-4 lg:mx-20 grow border-black/30 rounded-xl '>
                        <div className='px-10 py-10 text-justify md:text-md lg:text-xl'>
                            Otitis media (OM), the multifactorial middle ear disorder is known for affecting at least 80% of children during their childhood. This complex disease is also responsible for the deprived overall development of children due to the disabling hearing loss it causes.  Apart from the genetic association studies in humans, the data for co-study of contributing factors like the epigenetic contributors are very scarce and scattered in the field OM. GeMemiOM encompasses the otitis media associated gene details, CpG Islands(CGI) and Transcription Factor(TF)-binding status determining Short Nucleotide Variations(SNVs), Protein-Protein Interaction(PPI) data, coexpression data, pathway analysis data, miRNA targets and miRNA-lncRNA interactions. The 2000 bp flanking the transcription start site, retrieved from the EPDnew were annotated and made ready for the users inference. The above data are categorically archived and the images/tables can be visualized/browsed through. Hence to create a single point knowledge access platform for the genetic and accessory factors of otitis media, GeMemiOM-the first of its kind database for otitis media was designed.
                        </div>
                    </div>
                    <h1 className='my-2 text-2xl font-bold text-center lg:my-10'>How to use GeMemiOM</h1>
                    <div className='text-2xl bg-gray-100 border border-4 md:mx-20 grow border-black/30 rounded-xl'>

                        <Carousel swipeable={true} useKeyboardArrows={true} emulateTouch={true} showArrows={true} infiniteLoop={true}>
    
                            <div>
                                <img src="tutorial_images/step1.JPG" />
                                <p className="legend">Landing Page</p>
                            </div>
                            <div>
                                <img src="tutorial_images/Slide3.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide4.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide5.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide6.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide7.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide8.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide9.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide10.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>
                            <div>
                                <img src="tutorial_images/Slide11.JPG" />
                                {/* <p className="legend">Step 2</p> */}
                            </div>

                        </Carousel>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default About