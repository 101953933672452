import { createSlice } from "@reduxjs/toolkit";


const typeNameSlice = createSlice({
    name :"typename",
    initialState: {
        name : "",
    },
    reducers:{
        changename(state,action){
            state.name = action.payload
        }

    }
}) 

export const typeNameActions = typeNameSlice.actions;

export default typeNameSlice