import { createSlice } from "@reduxjs/toolkit";


const geneNameSlice = createSlice({
    name :"genename",
    initialState: {
        name : "",
    },
    reducers:{
        changename(state,action){
            state.name = action.payload
        }

    }
}) 

export const geneNameActions = geneNameSlice.actions;

export default geneNameSlice