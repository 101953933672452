import React from "react";
import Axios from 'axios';
import { useState, useEffect} from 'react';



function ViewImage(props) {

    const image_name = props.image_name
    console.log(image_name)
    const [image, setImage] = useState()

    useEffect( ()=>{
        Axios.get(`${process.env.REACT_APP_API}/images/${image_name}.JPG`,{responseType:"arraybuffer"}).then((response)=>{
            const base64 = btoa(
                            new Uint8Array(response.data).reduce(
                              (data, byte) => data + String.fromCharCode(byte),
                              ''
                            )
                          )
                          setImage(base64);
            
                    }
                    );
    },[props]);
    return (
        <div>
            <img className="drop-shadow-xl" src={`data:;base64,${image}`} alt='true' />
            {/* <button onClick={getImage}>Show image</button> */}
        </div>
    )
}

export default ViewImage