import React from 'react'
import { useSelector } from "react-redux"
import { useEffect } from 'react'
// import { motion } from 'framer-motion';
import { NavLink } from "react-router-dom"
import SearchBar from "../components/searchbar";
import { RiArrowDownSLine } from 'react-icons/ri'

function Landing() {
  
    const theme = useSelector((state) => state.thememode.name)
    // const typeNameState = useSelector((state) => state.typename.name)
    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme])

    // const handleThemeSwitch = () => {
    //     dispatch(darkModeActions.changemode(theme === 'dark' ? 'light' : 'dark'))
    //     console.log(`Theme changing to : ${theme}`)
    //     console.log(document.documentElement.classList)
    // }


    return (
        <div className='flex w-screen min-h-screen '>
            {/* <div className="flex flex-row content-center justify-center bg-white md:row-span-1 md:items-center dark:bg-gray-800 ">
                <div className='flex flex-col items-center justify-center w-3/4 lg:w-2/4'>
                    <div className='pb-5 font-bold text-md'>Select the genes related to otitis media from this drop down </div>
                    <SearchBar />
                </div>
            </div>
            <div className='row-span-2 mx-2 lg:mx-0 md:grid-cols-7 md:grid lg:flex-row bg-gradient-to-b from-sky-200 to-sky-100 dark:bg-gradient-to-b dark:from-gray-800 dark:to-gray-600 gap-7 '>
                <div className='flex flex-col justify-around col-span-5 col-start-2 mb-20 md:flex-row'>
                    <div className='flex flex-col my-4 text-center bg-white/30 md:w-1/4 lg:my-8 rounded-2xl drop-shadow-xl dark:bg-slate-600/30 md:my-6 backdrop-blur-sm dark:backdrop-blur-sm'>
                        <div className='row-span-1 py-2 mx-1 my-1.5 text-2xl font-bold bg-sky-400/30 rounded-2xl dark:bg-black dark:text-white '>
                            CGI and TF Binding  Status
                        </div>
                        <div className='px-4 pt-4 grow row-sapn-3 dark:text-white'>
                            Associated OM genes in humans and the short nucleotide variations (SNVs) present in the CpG islands (CGI) of the promoter regions annotated through our pipeline.
                            The status of CGIs (potential methylation targets in promoters) and transcription factor (TF) binding, varying due to the change in alleles of the SNVs harboring in the CGIs.</div>
                    </div>
                    <div className='flex flex-col my-4 text-center bg-white/30 backdrop-blur-sm md:w-1/4 lg:my-8 rounded-2xl drop-shadow-xl dark:bg-slate-600/30 dark:backdrop-blur-sm'>
                        <div className='row-span-1 py-2 mx-1 my-1.5 text-2xl font-bold bg-sky-400/30 rounded-2xl dark:bg-black dark:text-white'>
                            Protein Interactions
                        </div>
                        <div className='px-4 pt-4 grow row-sapn-3 dark:text-white'>
                            Interactome contains the Network data for the associated OM genes, coexpression /protein-protein interaction and involved miRNAs                         </div>
                    </div>
                    <div className='flex flex-col my-4 text-center bg-white/30 backdrop-blur-sm md:w-1/4 lg:my-8 rounded-2xl drop-shadow-xl dark:bg-slate-600/30 dark:backdrop-blur-sm'>
                        <div className='row-span-1 py-2 mx-1 my-1.5 text-2xl font-bold bg-sky-400/30 rounded-2xl dark:bg-black dark:text-white'>
                            miRNA interactions
                        </div>
                        <div className='px-4 pt-4 grow row-sapn-3 dark:text-white'>
                            imply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                        </div>
                    </div>
                </div>RiArrowDownSLine
            </div> */}
            <div className='flex flex-col w-full px-2 py-20 xl:px-10 lg:px-5 2xl:px-40 lg:gap-8 xl:bg-cyan-200/40 dark:bg-gray-800 dark:text-white'>
                <div className='flex flex-col items-center justify-around bg-white border-2 md:border-3 md:border-black/60 md:h-2/3 rounded-3xl dark:bg-black dark:text-white'>
                    <div className='flex flex-col items-center'>
                        <h1 className={`text-3xl font-bold sm:block hidden` }>GeMemiOM</h1>
                        <h2 className='px-10 pt-4 mt-2 font-bold text-center 2xl:text-2xl sm:px-0 sm:text-lg sm:pt-4'>Know about CGI and TF Binding Status</h2>
                    </div>
                    <div className='flex flex-col items-center py-10 my-6 md:w-1/2 md:py-2'>
                        <h1 className='text-lg font-bold'>Select Otitis Media related gene</h1>
                        <div className='w-full'>
                            <SearchBar />
                        </div>
                    </div>
                    <div className='flex flex-col items-center pt-10 mb-10 text-justify px-7 md:w-2/3 md:px-0 md:pt-0'>
                        <div className='text-xs lg:text-lg'>Otitis media (OM), the multifactorial middle ear disorder is known for affecting at least 80% of children during their childhood. This complex disease is also responsible for the deprived overall development of children due to the disabling hearing loss it causes. Apart from the genetic association studies in humans, the data for co-study of contributing factors like the epigenetic contributors are very scarce and scattered in the field OM. GeMemiOM encompasses the otitis media associated gene details...</div>
                        <NavLink to={"/about"} className='flex flex-col items-center pt-4 cursor-pointer dark:text-green-500'>
                            Know More
                            <RiArrowDownSLine />
                        </NavLink>
                    </div>


                </div>
                <div className='flex flex-col items-center justify-around w-full mt-4 bg-white sm:mt-0 md:border-2 md:border md:gap-0 border-black/20 md:h-1/3 rounded-3xl dark:bg-black dark:text-white md:mt-0'>
                    <h1 className='px-3 mt-2 mb-4 text-lg font-bold text-center sm:px-0 2xl:text-2xl 2xl:pt-2 sm:mb-0'>Functional Enrichments and Interactions</h1>
                    <div className='flex flex-col items-center justify-around w-full px-2 sm:font-bold sm:gap-5 2xl:text-xl md:px-2 grow lg:flex-row'>
                        <div className='w-full text-center sm:py-2 2xl:mb-4 2xl:py-8 xl:py-4 grow rounded-xl shadow-black/10 lg:w-60 lg:mx-0 dark:text-gray-200 '>
                            miRNA Interactions
                        </div>
                        <div className='w-full text-center sm:py-2 2xl:mb-4 2xl:py-8 xl:py-4 grow rounded-xl shadow-black/10 lg:w-60 lg:mx-0 dark:text-gray-200 '>
                            Co-Expression Interactions
                        </div>
                        <div className='w-full text-center sm:py-2 2xl:mb-4 2xl:py-8 xl:py-4 grow rounded-xl shadow-black/10 lg:w-60 lg:mx-0 dark:text-gray-200 '>
                            Protein Interactions
                        </div>
                        <div className='w-full text-center sm:py-2 2xl:mb-4 2xl:py-8 xl:py-4 grow rounded-xl shadow-black/10 lg:w-60 lg:mx-0 dark:text-gray-200 '>
                            Genetic Interactions
                        </div>
                        <div className='w-full text-center sm:py-2 2xl:mb-4 2xl:py-8 xl:py-4 grow rounded-xl shadow-black/10 lg:w-60 lg:mx-0 dark:text-gray-200 '>
                            Pathways
                        </div>
                        <div className='w-full text-center sm:py-2 2xl:mb-4 2xl:py-8 xl:py-4 grow rounded-xl shadow-black/10 lg:w-60 lg:mx-0 dark:text-gray-200 '>
                            miRNA-lncRNA Interactions
                        </div>
                    </div>
                    <NavLink to={"/network"} className='w-40 py-1 mt-3 mb-3 font-bold text-center bg-white shadow-sm cursor-pointer sm:mt-0 sm:py-4 2xl:text-xl rounded-xl shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-xl dark:bg-gray-800 dark:text-green-500'>Click to Go!</NavLink>
                </div>
            </div>
        </div>
    )
}
export default Landing