import React from 'react'

function Footer(){
    return(
        <div className='flex justify-center py-3 text-sm font-bold text-center text-gray-500 bg-white/50 backdrop-blur-sm dark:backdrop-blur-sm dark:text-gray-600 dark:bg-black/30 md:text-base'>
            Human/Medical Genetics Lab | Computational Biology and Bioinformatics Lab
        </div>
    )
}

export default Footer