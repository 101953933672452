import { createSlice } from "@reduxjs/toolkit";

const snpListSlice = createSlice({
    name : "snp",
    initialState:{

        list: []
    },
    reducers:{
        createsnpnamelist (state, action){
            // console.log(action)
            // state.list.push([...state, action.payload])
            state.list =  action.payload
            // console.log(` Inside the store ${state.list}`)
        }
    }

})

export const snpListActions = snpListSlice.actions
export default snpListSlice