import React from 'react'
import NetworkPage from '../components/network_page';
import Navbar from '../components/Navbar';
import { useState } from 'react'
import { motion } from 'framer-motion'
import PathwayPage from '../components/pathway_page';
function FunctionalEnrichment() {
    // const [apinodes, setApiNodes] = useState([])
    // const [apiedges, setApiEdges] = useState([])
    const [feSelection, setFeSelection] = useState('network')

    // useEffect(() => {
    //     // console.log(`this is not working response.data`)
    //     Axios.get("http://192.168.23.232:3001/nodes").then((response) => {
    //         console.log(response.data)
    //         setApiNodes(response.data);

    //     });
    //     Axios.get("http://192.168.23.232:3001/edges").then((response) => {
    //         console.log(response.data)
    //         setApiEdges(response.data);

    //     });


    // }, [])

    // const graph = {
    //     nodes: apinodes,
    //     edges: apiedges
    // }
    // const options = {
    //     physics: false,
    //     layout: {
    //         hierarchical: false
    //     },
    //     edges: {
    //         color: "#000000"
    //     },
    //     height: "100%",
    //     width: "100%"
    // };

    // const events = {
    //     select: function (event) {
    //         var { nodes, edges } = event;
    //     }
    // };

    let selected_page
    if (feSelection === "network") {
        selected_page = <NetworkPage />
    } else if (feSelection === "pathway") {
        selected_page = <PathwayPage/>
    }
    console.log(feSelection)


    return (
        <div className='h-full dark:bg-black '>
            <div className="fixed z-50 flex w-screen mb-10 flex-nowrap drop-shadow-lg backdrop-blur-sm">
                <Navbar />
            </div>
            <div className='flex justify-center mx-5 sm:mx-0 sm:pt-20'>
                <div className='flex justify-around pt-20 py- 6 sm:gap-10 sm:px-10 sm:w-3/4'>

                    <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                        type: "spring",
                        damping: 10,
                        mass: 0.75,
                        stiffness: 100,
                    }} className={`grow flex items-center justify-center w-40 h-20 px-3 py-2 mx-10 text-gray-800 ${feSelection === "network" ? "bg-gray-300 font-bold":"bg-white"} rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setFeSelection("network")}>
                        Network
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                        type: "spring",
                        damping: 10,
                        mass: 0.75,
                        stiffness: 100,
                    }} className={`grow flex items-center justify-center w-40 h-20 px-3 py-2 mx-10 text-gray-800 ${feSelection === "pathway" ? "bg-gray-300 font-bold":"bg-white"}  rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setFeSelection("pathway")}>
                        Pathway
                    </motion.div>
                </div>
            </div>

            <div className='flex w-screen h-full bg-gray-300'>
                {selected_page}

            </div>

        </div>
    )
}

export default FunctionalEnrichment