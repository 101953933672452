import React from "react";
import { useEffect } from "react";
import Axios from 'axios'
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux"
import { variantListActions } from "../store/variantListSlice";
import { variantNameActions } from "../store/varientNameSlice";
// import TypeRedux from "./typeRedux";
import { selectedActions } from "../store/selectedSection"
import {useNavigate} from 'react-router-dom'


function VariantOptions() {

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });}
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const typeNameState = useSelector((state) => state.typename.name)
    const snpNameState = useSelector((state) => state.snpname.name)
    const geneNameState = useSelector((state) => state.genename.name)
    const promoterNameState = useSelector((state) => state.promotername.name)
    const variantData = useSelector((state) => state.variantlist.list)
    // const variantNameState = useSelector((state) => state.variantname.name)

    // console.log("Inside the VAriant page")

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/data?gene_name=${geneNameState}&promoter_number=${promoterNameState}&snp_id=${snpNameState}`).then((response) => {
            dispatch(variantListActions.createvariantnamelist(response.data))
        });
    }, [geneNameState, snpNameState, promoterNameState]);
    
    useEffect(()=>{
        scrollToBottom()
    },[snpNameState])

    // let type_page
    // if (variantNameState !== "") {
    //     type_page = <TypeRedux />
        
    //     // console.log("This is variant page")
    // }


    return (
        <div className="flex flex-col justify-center min-w-screen">
            {/* <h1 className="text-xl font-bold text-center">{typeNameState}</h1> */}
            <div className="flex items-center justify-center pt-10 text-2xl font-bold text-gray-600 dark:text-gray-200">
                Available Options To Analyze
            </div>
            <div className="flex justify-center py-3 min-w-screen sm:mx-0">
                {
                    variantData.map((val, key) => {
                        console.log(variantData)

                        const setVariantName = () => {
                            console.log("Action Clicked Variant")
                            dispatch(variantNameActions.changename(val.variant))
                            dispatch(selectedActions.changename("analysis"))
                            navigate("/results")

                        }

                            return (<div className="px-5 py-2 mx-4">
                                


                                <motion.div whileHover={{ scale: 1.2 }} initial={{ scale: 0.3, opacity: 0, y: 60 }}
                                    animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                                        type: "spring",
                                        damping: 10,
                                        mass: 0.75,
                                        stiffness: 100,
                                    }} onClick={setVariantName} className="flex items-center justify-center w-20 h-10 bg-white rounded-lg shadow-sm cursor-pointer sm:h-20 sm:w-40 sm:py-2 sm:px-3 sm:mx-10 shadow-black/50 lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black hover:bg-800 dark:hover:shadow-green-500/50 dark:bg-gray-800">
                                    <ul className="flex flex-wrap text-2xl font-bold text-gray-800 rounded-lg sm:px-5 sm:py-5 text-md hover:text-black dark:hover:text-green-500 dark:text-white">{val.variant}
                                    </ul>

                                </motion.div>
                            </div>)
                        })
                }
            </div>
            {/* <div>
                {type_page}
            </div> */}
        </div>
    )

}

export default VariantOptions