import React from 'react'
import { useState, useEffect } from 'react'
import Select from 'react-select'
import Axios from 'axios'
import fileDownload from 'js-file-download';
import { motion } from 'framer-motion'


function PathwayPage() {

    const [image, setImage] = useState()
    const [bgImage, setBgImage] = useState()
    const [pathwayOptions, setPathwayOptions] = useState([]);
    const [pathwayType, setPathwayType] = useState("data_pathway")
    const [pathwaysList, setPathwayList] = useState([])
    const [selected, setSelected] = useState(false);
    const [pathwayName, setPathwayName] = useState("")
    const [loading, setLoading] = useState(false);

    const downloadImage = (e) => {
        e.preventDefault()
        Axios({
            url: `https://reactome.org/ContentService/exporter/diagram/${pathwayName}.png?quality=10`,
            method: "GET",
            responseType: 'blob'
        }).then((res) => {
            fileDownload(res.data, `${pathwayName}.png`)
        })
    }







    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/${pathwayType}?gene_name=${selected}`).then((resp) => {
            console.log(resp.data)
            setPathwayList(resp.data);
            setPathwayName(resp.data[0].Pathway_identifier)


        });
    }, [selected,pathwayType]);

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/${pathwayType}`).then((resp) => {
            // console.log("This is the resp data")
            // console.log(resp.data[0].gene_name)
            // console.log('The above is the resp data')
            setPathwayOptions(resp.data);
            // setPathwayName(resp.data[0].gene_name)
        });
    }, [pathwayType]);

    useEffect(() => {
        setLoading(true);
        Axios.get(`https://reactome.org/ContentService/exporter/diagram/${pathwayName}.jpg`, { responseType: "arraybuffer" }).then((response) => {
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setImage(base64);
            setLoading(false);

        }
        );
    }, [pathwayType, selected, pathwayName])

    useEffect(() => {
      
        Axios.get(`${process.env.REACT_APP_API}/networks/background/pathway_bg.jpg`, { responseType: "arraybuffer" }).then((response) => {
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setBgImage(base64);
            

        }
        );
    }, [])





    const handleChange = (event) => {
        setSelected(event.value);
    }

    const Options = pathwayOptions.map(d => ({
        "value": d.gene_name,
        "label": d.gene_name
    }))
    let pathwaytype
    if (pathwayType === "data_pathway") {
        pathwaytype = "Pathway details form Reactome"

    } else if (pathwayType === "kegg_pathway") {
        pathwaytype = "Sorry, Data not available"
    }




    return (
        <div className='flex flex-col items-center h-full dark:bg-black grow'>
            <div className={`z-10 flex justify-around w-full gap-20 px-10 py-5 sm:w-1/2`}>

                <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`flex items-center justify-center   sm:w-1/4 sm:h-20 px-3 py-2 sm:mx-10 text-gray-800 bg-white ${selected ? "hover:bg-white":"hover:bg-white/20"} rounded-lg shadow-sm cursor-pointer grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setPathwayType("data_pathway")}>
                    Reactome Pathways
                </motion.div>
                {/* <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`flex items-center justify-center w-1/4 h-20 px-3 py-2 mx-10 text-gray-800 bg-white ${selected ? "hover:bg-white":"hover:bg-white/20"} rounded-lg shadow-sm cursor-pointer grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setPathwayType("kegg_pathway")}>
                    KEGG Pathways
                </motion.div> */}
            </div>
            {<div className='z-20 w-1/2 py-10 dark:text-white'>
                <div className='pb-3 text-xl font-bold text-center'>
                    {pathwaytype}
                </div>
                <h1 className="pb-10 text-2xl font-bold text-center">
                    {selected}
                </h1>
                <Select onChange={handleChange} options={Options} className="dark:text-black">


                    {pathwayOptions.map(option => {


                        <option key={option.value} value={option.value}>
                            {option.value}

                        </option>



                    })}

                </Select>
            </div>
            }
            {/* <div>
            {
                    pathwaysList.map((val, key) => {

                        return (<div className="px-5 py-2">
                            val
                        </div>)
                    })
                }
            </div> */}
            {selected &&
                <div className='z-10'>
                    <div className='w-screen sm:px-20'>
                        <div className='flex flex-col mx-2 text-center bg-white border border-2 border-black rounded-md sm:mx-0 sm:flex-row dark:bg-gray-800 dark:text-white'>
                            <div className='text-xl sm:w-2/5'>
                                <div className='py-4 text-2xl font-bold'>
                                    Pathway names
                                </div>

                                <div className='w-full sm:py-20'>
                                    {
                                        pathwaysList.map((val, key) => {
                                            console.log("Entered in to the TYPE DATA")
                                            // const [geneName, setGeneName] = useState('')

                                            const setTypeName = () => {
                                                // console.log("Action Clicked TYPE")
                                                setPathwayName(val.Pathway_identifier)
                                                // console.log(val.Pathway_identifier)
                                                // console.log(pathwayType)
                                                // dispatch(typeNameActions.changename(val.type))
                                            }
                                            return (<div className="px-5 py-2">


                                                <motion.div whileHover={{ scale: 1.1 }} transition={{
                                                    type: "spring",
                                                    damping: 10,
                                                    mass: 0.75,
                                                    stiffness: 100,
                                                }} onClick={setTypeName} className={`px-3 py-2 ${pathwayName === val.Pathway_identifier ? "bg-gray-300 dark:text-green-500 font-bold":"bg-white"} border border-black rounded-lg cursor-pointer drop-shadow-sm dark:bg-gray-700 hover:drop-shadow-xl`}>
                                                    <ul className="">{val.Pathway_name}
                                                    </ul>

                                                </motion.div>
                                            </div>)
                                        })
                                    }

                                    {/* <img className="" src={`data:;base64,${image}`} alt='true' /> */}
                                    {/* <img src={image}/> */}
                                    {/* <img src={require(image).default} alt='mySvgImage' /> */}
                                    {/* {console.log(`${process.env.REACT_APP_API}/networks/${networkType}/${selected}.svg`)} */}

                                </div>
                            </div>
                            <div className='sm:w-3/4 '>


                                {loading ? <div className='flex items-center justify-center h-full my-10 sm:my-0 sm:text-3xl'> Pathway is loading...</div> : <img className="" src={`data:;base64,${image}`} alt='View in high res' />}
                            </div>
                        </div>
                        <div className='flex flex-row justify-center w-full gap-10 px-5 py-10 dark:text-white'>
                            <div onClick={(e) => downloadImage(e)} className='flex items-center justify-center w-20 py-2 font-bold text-center text-gray-800 bg-green-500 rounded-lg shadow-sm cursor-pointer sm:h-20 sm:px-3 sm:text-xl sm:mx-10 grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500'>
                                Download in High Quality
                            </div>
                            <a href = {`https://reactome.org/ContentService/exporter/diagram/${pathwayName}.png?quality=10`} className='flex items-center justify-center w-20 py-2 font-bold text-center text-gray-800 bg-green-500 rounded-lg shadow-sm cursor-pointer sm:h-20 sm:px-3 sm:text-xl sm:mx-10 grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500'>
                                View in High Quality
                            </a>
                        </div>
                    </div>
                </div>
            }
            { <div className={`absolute ${selected ? "opacity-20":"opacity-50"}`}>
                <img className="-z-50" src={`data:;base64,${bgImage}`} alt='View in high res' />
            </div>}


        </div>

    )
}


export default PathwayPage