import React from 'react'
import ViewImage from './ViewImage'
import { motion } from "framer-motion";
import {  useSelector } from "react-redux"
function ComparePage() {
  // const typeNameState = useSelector((state) => state.typename.name)
  const snpNameState = useSelector((state) => state.snpname.name)
  const geneNameState = useSelector((state) => state.genename.name)
  const variantNameState = useSelector((state) => state.variantname.name)
  const promoterNameState = useSelector((state) => state.promotername.name)
  const typeData = useSelector((state) => state.typelist.list)



  return (
    <div className='w-full min-h-screen bg-white dark:bg-black'>
      <div className="flex flex-col items-center justify-center">
        {
          typeData.map((val, key) => {
            console.log(typeData)
            const image_name = `${geneNameState}_${promoterNameState}_${snpNameState}_${val.type}_${variantNameState}`
            // const setTypeName = () => {
            //   console.log("Action Clicked Variant")

            //   setCompstate("single")
            // }
            return (<div className="px-4 py-5">


              <motion.div whileHover={{ scale: 1.1 }} initial={{scale:0.3, opacity: 0 ,y:60}}
                                    animate={{scale:1, opacity: 1 ,y:0}} transition={{
                type: "spring",
                damping: 10,
                mass: 0.75,
                stiffness: 100,
              }} className="">
                <ul className="font-bold dark:text-white">{val.type}
                </ul>
                <div>
                  <ViewImage image_name={image_name} />
                </div>

              </motion.div>
            </div>)
          })
        }
      </div>

    </div>
  )
}


export default ComparePage