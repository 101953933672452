import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import geneListSlice  from "./geneListSlice";
import geneNameSlice from "./geneNameSlice";
import snpListSlice from "./snpListSlice";
import snpNameSlice from "./snpNameSlice";
import typeListSlice from "./typeListSlice";
import typeNameSlice from "./typeNameSlice";
import variantListSlice from "./variantListSlice";
import variantNameSlice from "./varientNameSlice";
import promoterListSlice from "./promoterListSlice";
import promoterNameSlice from "./promoterNameSlice";
import selectedSlice from "./selectedSection";
import darkModeSlice from "./darkModeSlice";


const persistConfig = {
    key:'root',
    storage,
}

const rootReducer = combineReducers({
    thememode : darkModeSlice.reducer,
    genename : geneNameSlice.reducer,
    genelist : geneListSlice.reducer,
    snpname : snpNameSlice.reducer,
    snplist : snpListSlice.reducer,
    typename : typeNameSlice.reducer,
    typelist : typeListSlice.reducer,
    variantname : variantNameSlice.reducer,
    variantlist : variantListSlice.reducer,
    promotername : promoterNameSlice.reducer,
    promoterlist : promoterListSlice.reducer,
    selectionname: selectedSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer
    
})

export const persist_store = persistStore(store)