import { createSlice } from "@reduxjs/toolkit";

const darkModeSlice = createSlice({
    name : "thememode",
    initialState:{

        name: "light"
    },
    reducers:{
        changemode (state, action){
            console.log("Action called")
            // state.list.push([...state, action.payload])
            state.name =  action.payload
            // console.log(` Inside the store ${state.list}`)
        }
    }

})

export const darkModeActions = darkModeSlice.actions
export default darkModeSlice