import { createSlice } from "@reduxjs/toolkit";

const variantListSlice = createSlice({
    name : "variant",
    initialState:{

        list: []
    },
    reducers:{
        createvariantnamelist (state, action){
            // console.log(action)
            // state.list.push([...state, action.payload])
            state.list =  action.payload
            // console.log(` Inside the store ${state.list}`)
        }
    }

})

export const variantListActions = variantListSlice.actions
export default variantListSlice