import React from 'react'
import Navbar from '../components/Navbar'

function Contact() {
    return (
        <div>
            <div className="fixed z-50 flex w-screen mb-10 bg-white flex-nowrap drop-shadow-lg backdrop-blur-sm dark:bg-black">
                <Navbar />
            </div>
            <div className='flex flex-col justify-around min-h-screen py-20 dark:bg-black dark:text-white'>
                {/* <div>
                    Heading
                </div> */}
                <div className='flex flex-col justify-around gap-5 text-center sm:gap-0 sm:flex-row'>

                    <div className=''>
                        <h1 className='text-2xl font-bold sm:text-3xl text-sky-800 dark:text-sky-500'>Dr. P V Ramchander</h1>
                        <p>Scientist - F</p>
                        <p>Human/ Medical Genetics Lab</p>
                        <p>E-mail : pramchander@yahoo.com</p>
                        <p>E-mail : pvram@ils.res.in</p>
                    </div>
                    <div>
                        <h1 className='text-2xl font-bold sm:text-3xl text-sky-800 dark:text-sky-500'>Dr. Anshuman Dixit</h1>
                        <p>Scientist - E</p>
                        <p>Computational Biology and Bioinfromatics Lab</p>
                        <p>E-mail : anshumandixit@gmail.com</p>
                        <p>E-mail : anshuman@ils.res.in</p>
                    </div>
                </div>
                <div className='flex flex-col gap-5 pt-10 text-center md:pt-0'>
                    <div className='grow'>
                        <h1 className='text-xl font-bold md:text-2xl text-sky-800 dark:text-green-500'>Kondyarpu Abhishek</h1>
                        <p>DST-INSPIRE SRF</p>
                        <p>Human/ Medical Genetics Lab</p>
                        <p>E-mail : kabhishek.master@gmail.com</p>
                        <p>E-mail : k.abhishek@ils.res.in</p>
                        
                    </div>
                    <div className='grow'>
                        <a href='https://bineetx.github.io' className='text-xl font-bold md:text-2xl text-sky-800 dark:text-green-500'>Bineet Kumar Mohanta</a>
                        <p>CSIR-JRF</p>
                        <p>Computational Biology and Bioinfromatics Lab</p>
                        <p>E-mail : bineetkumarmohanta@gmail.com</p>
                        <p>E-Mail : bineet@ils.res.in</p>
                    </div>
                    <div className='grow'>
                        <h1 className='text-xl font-bold md:text-2xl text-sky-800 dark:text-green-500'>Pratima Kumari</h1>
                        <p>UGC-SRF</p>
                        <p>Computational Biology and Bioinfromatics Lab</p>
                        <p>E-mail : pratimakumari350@gmail.com</p>
                        <p>E-mail : pratima@ils.res.in</p>
                        
                    </div>

                </div>
                <div className='flex flex-col pt-10 text-center md:pt-0'>
                    <h1 className='text-4xl font-bold'>Institute of Life Sciences</h1>
                    <p>An Autonomous Institute of Department of Biotechnology, Government of India</p>
                    <p>Bhubaneswar, Odisha, India, 751023</p>
                </div>
            </div>
        </div>
    )
}

export default Contact