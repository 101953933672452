import React from "react";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react'
import { selectedActions } from "../store/selectedSection"
import { useDispatch, useSelector } from "react-redux"
import { darkModeActions } from '../store/darkModeSlice'
// import SearchBar from "./searchbar";
import { GiHamburgerMenu } from 'react-icons/gi'
import { RxCross2 } from 'react-icons/rx'





const Navbar = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.thememode.name)
  // const selectedSection = useSelector((state) => state.selectionname.name)
  const [navbar, setNavbar] = useState(false);
  const [searchBar, setSearchBar] = useState(true);
  // const typeNameState = useSelector((state) => state.typename.name)
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme])

  const handleThemeSwitch = () => {
    dispatch(darkModeActions.changemode(theme === 'dark' ? 'light' : 'dark'))
    // console.log(`Theme changing to : ${theme}`)
    // console.log(document.documentElement.classList)
  }
  // let selecetedView
  // if (selectedSection !== "") {
  //   selecetedView = <SearchBar />


  // }

  return (
    <nav className="w-screen 2xl:py-2 dark:text-white navbar/30 ">
      <div onClick={() => setNavbar(!navbar)} className="absolute text-xl font-bold rounded-lg dark:bg-black/30 dark:text-white md:hidden top-2 right-6">
        {navbar ? <RxCross2 /> : <GiHamburgerMenu />}
        {/* {console.log(navbar)} */}
      </div>
      <div className="grid-cols-4 md:grid">
        <div className="flex flex-col items-center col-span-2 grow lg:flex-row">
          <div onClick={() => dispatch(selectedActions.changename(""))} className={`px-4 text-4xl sm:text-3xl ${navbar ? 'text-center' : ''} font-bold cursor-pointer 2xl:text-5xl text-sky-800 dark:text-sky-500`}>
            GeMemiOM
          </div>
          <div className={`flex lg:block md:block ${navbar ? 'block' : 'hidden'} justify-center`}>
            {/* <h1 className="text-lg font-bold">
              GetMemiOM           </h1> */}
            <p className="w-2/3 pt-2 font-bold text-center xl:pt-0 2xl:text-sm 2xl:px-3 lg:px-0 xl:text-xs sm:pt-4">
              <span className="text-xl font-bold text-red-800 2xl:text-xl">Ge</span>nes, Putative <span className="text-xl font-bold text-red-800">Me</span>thylation Study Targets and <span className="text-xl font-bold text-red-800">mi</span>RNA Targets For <span className="text-xl font-bold text-red-800">O</span>titis <span className="text-xl font-bold text-red-800">M</span>edia
            </p>
          </div>

        </div>
        {/* <div className="items-center justify-center w-full col-span-1 py-3 md:px-2">
          {searchBar && selecetedView}
        </div> */}

        {/* <Link to="/">
          <img src={logo} alt="CocktailDB" className="logo" />
        </Link> */}
        <ul className={`flex grow md:h-full h-screen flex-col md:py-3 md:col-span-2 md:justify-around md:flex-row md:flex nav-links justify-center gap-3 ${navbar ? 'bg-black/10' : ''} ${navbar ? "block" : "hidden"} items-center py-5 md:text-base text-xl md:font-base font-bold`}>
          <li className="py-1 md:py-0 dark:hover:text-green-400 hover:text-green-600">
            <NavLink to={"/"} onClick={() => setSearchBar(true)} >Home</NavLink>
          </li>
          <li className="py-1 md:py-0 dark:hover:text-green-400 hover:text-green-600">
            <NavLink to={"/about"} onClick={() => setSearchBar(false)}>About</NavLink>
          </li>

          <li className="py-1 md:py-0 dark:hover:text-green-400 hover:text-green-600">
            <NavLink to={"/network"} onClick={() => setSearchBar(false)}>Functional Enrichments</NavLink>
          </li>

          <li className="py-1 md:py-0 dark:hover:text-green-400 hover:text-green-600">
            <NavLink to={"/contact"} onClick={() => setSearchBar(false)}>Contact Us</NavLink>
          </li >
          <li className="py-1 pb-10 cursor-pointer md:pb-0 dark:hover:text-green-400 hover:text-green-600" onClick={handleThemeSwitch}>
            {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;