
import React from 'react';
import { useEffect } from "react";
import Axios from 'axios'
// import SNPRedux from "./snpRedux";
import { useDispatch, useSelector } from "react-redux"
import { snpListActions } from "../store/snpListSlice";
import { snpNameActions } from "../store/snpNameSlice";
import { motion } from 'framer-motion';
// import VariantOptions from './variantoptions';


function CourosalSNPs() {



    const dispatch = useDispatch()
    // const snpNameState = useSelector((state) => state.snpname.name)
    const geneNameState = useSelector((state) => state.genename.name)
    const promoterNameState = useSelector((state) => state.promotername.name)
    const snpData = useSelector((state) => state.snplist.list)
    // console.log(`The promoter name is ${promoterNameState}`)
    useEffect(() => {

        Axios.get(`${process.env.REACT_APP_API}/data?gene_name=${geneNameState}&promoter_number=${promoterNameState}`).then((response) => {
            // console.log("called the api")

            dispatch(snpListActions.createsnpnamelist(response.data))
        });
    }, [promoterNameState, geneNameState]);


    const parent = {
        hidden: {},
        show: {
            rotate: 0,
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3,
            },
        },
    }








    return (
        <div className='flex flex-col items-center'>
            <div className="flex items-center pt-10 text-2xl font-bold text-gray-600 dark:text-gray-200">
                Available SNPs in CGIs
            </div>
            <div className="flex grid flex-col justify-center grid-cols-5 min-w-screen">



                <motion.div variants={parent} initial="hidden" animate="show" className="flex col-span-5 px-10 py-3 overflow-x-auto hover:scroll-auto snap-mandatory lg:col-span-3 lg:col-start-2 scroll-m-8 w-100 overscroll-contain scroll-smooth scroll-pl-6 snap-x drop-shadow-lg">
                    {/* {console.log(snpData)}
                    {console.log("Inside the gene page")} */}


                    {
                        snpData.map((val, key) => {
                            // console.log("Entered into the map ")


                            const setSnpName = () => {
                                // console.log("Action Clicked")
                                dispatch(snpNameActions.changename(val.snp_id))
                            }


                            return (
                                <div className="mx-5">


                                    <motion.div whileHover={{ scale: 1.1 }} initial={{ scale: 0.7, opacity: 0, y: 60 }}
                                        animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                                            type: "spring",
                                            damping: 10,
                                            mass: 0.75,
                                            stiffness: 100,
                                        }} onClick={setSnpName} href="#type" className="flex h-40 px-2 py-4 bg-white border border-black rounded-lg cursor-pointer dark:bg-black w-60 hover:drop-shadow-xl snap-end min-w-60">
                                        {console.log()}
                                        <ul className="flex items-center justify-center px-4 text-3xl font-bold bg-white rounded-lg grow dark:bg-gray-800 dark:text-white ">{val.snp_id}


                                        </ul>
                                        {/* <div className='flex items-center justify-center h-20 px-1 mx-1 my-3 text-center bg-white rounded-lg'>
                                        
                                        <div className='px-4 py-4 text-white bg-black rounded-lg'>
                                            View In dbSNP
                                        </div>
                    
                                       
                                    </div> */}

                                    </motion.div>


                                </div>)
                        })
                    }
                </motion.div>

                {/* 
            <div>
            {variant_page}
            </div> */}
            </div>
        </div>
    )

}

export default CourosalSNPs