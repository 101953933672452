import React from 'react'
import ViewImage from './ViewImage'

import { useSelector} from "react-redux"

function VariantRedux() {



    const typeNameState = useSelector((state)=> state.typename.name)
    const snpNameState = useSelector((state)=> state.snpname.name)
    const geneNameState = useSelector((state)=> state.genename.name)
    const variantNameState = useSelector((state)=> state.variantname.name)
    const promoterNameState = useSelector((state)=>state.promotername.name)


    const image_name = `${geneNameState}_${promoterNameState}_${snpNameState}_${typeNameState}_${variantNameState}`
  return (
    <div>
        <ViewImage image_name={image_name}/>
    </div>
  )
}

export default VariantRedux