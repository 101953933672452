import React from 'react'
import { useState, useEffect } from 'react'
import Select from 'react-select'
import Axios from 'axios'
import fileDownload from 'js-file-download';
import { motion } from 'framer-motion'
import { MdFileDownload } from 'react-icons/md'
import { ReactSVG } from 'react-svg'


function NetworkPage() {

    const [image, setImage] = useState()
    const [networkOptions, setNetworkOptions] = useState([]);
    const [networkType, setNetworkType] = useState("genes_network")
    const [selected, setSelected] = useState("A2ML1");
    const [networkTable, setNetworkTable] = useState([])
    const [imageAvailable, setImageAvailable] = useState(false)

    const downloadCSV = (e) => {
        e.preventDefault()
        Axios({
            url: `${process.env.REACT_APP_API}/download?location=csv_files&type=networks/${networkType}&file=${selected}.csv`,
            method: "GET",
            responseType: 'blob'
        }).then((res) => {
            fileDownload(res.data, `${selected}.csv`)
        })
    }
    const downloadAllLNC = (e) => {
        e.preventDefault()
        Axios({
            url: `${process.env.REACT_APP_API}/download?location=csv_files&type=networks/lnc_originals&file=${selected}.csv`,
            method: "GET",
            responseType: 'blob'
        }).then((res) => {
            fileDownload(res.data, `${selected}.csv`)
        })
    }

    const downloadImage = (e) => {
        e.preventDefault()
        Axios({
            url: `${process.env.REACT_APP_API}/download?location=image_files&type=networks/${networkType}&file=${selected}.svg`,
            method: "GET",
            responseType: 'blob'
        }).then((res) => {
            fileDownload(res.data, `${selected}.svg`)
        })
    }



    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/${networkType}`).then((resp) => {
            // console.log(resp.data)
            setNetworkOptions(resp.data);
        });
    }, [networkType]);

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/${networkType}_table?target_name=${selected}`).then((resp) => {
            console.log(resp.data)
            setNetworkTable(resp.data);
            console.log(selected)
        });
    }, [selected, networkType]);


    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/networks/${networkType}/${selected}.jpg`, { responseType: "arraybuffer" }).then((response) => {
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setImage(base64);
            // setImage(response.data)
            setImageAvailable(true)

        }
        ).catch((error) => {
            setImageAvailable(false)
        });
    }, [networkType, selected])





    const handleChange = (event) => {
        setSelected(event.value);
    }

    const Options = networkOptions.map(d => ({
        "value": d.gene_name,
        "label": d.gene_name
    }))
    let networktype
    if (networkType === "coexpression_network") {
        networktype = "Co-Expression Interaction"

    } else if (networkType === "mirna_network") {
        networktype = "miRNA Interaction"
    } else if (networkType === "genes_network") {
        networktype = "Protein-Protein Interaction"
    } else if (networkType === "geneticinteractions_network") {
        networktype = "Genetic Interactions"
    } else if (networkType === "lncinteractions_network") {
        networktype = "miRNA-lncRNA Interactions"
    }





    return (
        <div className='flex flex-col items-center w-screen h-full py-20 bg-white dark:bg-black grow'>

            <div className='flex flex-col justify-around w-full gap-2 px-10 py-2 sm:py-6 sm:gap-10 sm:flex-row'>
                <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`grow flex items-center justify-center ${networkType === "genes_network" ? "bg-gray-300 font-bold" : "bg-white"} sm:w-40 sm:h-20 sm:px-3 py-2 sm:mx-10 text-gray-800 bg-white rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setNetworkType("genes_network")}>
                    Protein
                </motion.div>
                <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`grow flex items-center justify-center ${networkType === "mirna_network" ? "bg-gray-300 font-bold" : "bg-white"} sm:w-40 sm:h-20 sm:px-3 py-2 sm:mx-10 text-gray-800 bg-white rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => { setNetworkType("mirna_network") }}>
                    miRNA
                </motion.div>
                <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`grow flex items-center justify-center ${networkType === "coexpression_network" ? "bg-gray-300 font-bold" : "bg-white"} sm:w-40 sm:h-20 sm:px-3 py-2 sm:mx-10 text-gray-800 bg-white rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setNetworkType("coexpression_network")}>
                    Co-Expression
                </motion.div>

                <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`grow flex items-center justify-center ${networkType === "geneticinteractions_network" ? "bg-gray-300 font-bold" : "bg-white"} sm:w-40 sm:h-20 sm:px-3 py-2 sm:mx-10 text-gray-800 bg-white rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setNetworkType("geneticinteractions_network")}>
                    Genetic Interactions
                </motion.div>
                <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`grow flex items-center justify-center ${networkType === "lncinteractions_network" ? "bg-gray-300 font-bold" : "bg-white"} sm:w-40 sm:h-20 sm:px-3 py-2 sm:mx-10 text-gray-800 bg-white rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={() => setNetworkType("lncinteractions_network")}>
                    miRNA-lncRNA Interactions
                </motion.div>

            </div>
            <div className='w-1/2 py-10 '>
                <div className='pb-3 text-xl font-bold text-center'>
                    {networktype} Network Options
                </div>
                <h1 className="pb-10 text-2xl font-bold text-center">
                    {selected}
                </h1>

                <Select onChange={handleChange} options={Options}>


                    {networkOptions.map(option => {


                        <option key={option.value} value={option.value}>
                            {option.value}
                            {/* {console.log(option)} */}

                        </option>



                    })}

                </Select>
            </div>

            <div>

                {imageAvailable ? <img className="" src={`data:;base64,${image}`} alt='true' /> : <h1 className='py-20 text-2xl font-bold'>Sorry , {networktype} Data not available for {selected}</h1>}
                {/* {imageAvailable ? <ReactSVG src={`${image}`} />: <h1 className='py-20 text-2xl font-bold'>Sorry , {networktype} Data not available for {selected}</h1>} */}

                {/* <img src={image}/> */}
                {/* <img src={require(image).default} alt='mySvgImage' /> */}
                {/* {console.log(`${process.env.REACT_APP_API}/networks/${networkType}/${selected}.svg`)} */}

            </div>
            <div className='flex border rounded-lg sm:w-1/2 sm:mb-4 sm:py-4 sm:px-4'>
                <table className="min-w-full ">
                    <thead className='bg-white border-b'>
                        <tr className='bg-gray-300'>
                            <th scope="col" className="px-6 py-4 text-sm font-bold text-left text-gray-900">Node1</th>
                            <th scope="col" className="px-6 py-4 text-sm font-bold text-left text-gray-900">Node2</th>
                            <th scope="col" className="px-6 py-4 text-sm font-bold text-center text-gray-900">{networkType === "lncinteractions_network" ? "Node3" : "Score"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            networkTable.map((val) => {
                                return (

                                    <tr className='transition duration-300 ease-in-out bg-white border-b hover:bg-gray-200 hover:dark:bg-gray-700'>
                                        <td className='px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap dark:bg-black dark:text-white '>{val.Node1}</td>
                                        <td className='px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap dark:bg-black dark:text-white '>{val.Node2}</td>
                                        <td className='px-6 py-4 text-sm font-light text-center text-gray-900 whitespace-nowrap dark:bg-black dark:text-white '>{networkType === "lncinteractions_network" ? val.Node3 : val.Score}</td>
                                    </tr>)
                            })
                        }
                    </tbody>
                </table>

            </div>

            <div className='flex justify-center w-full gap-10 mt-5 sm:mt-0 sm:gap-20 sm:px-10 sm:py-10 sm:mx-0'>
                <motion.div whileHover={{ scale: 1.05 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`flex items-center text-center justify-center h-20 px-3 py-2 sm:mx-10 text-xl font-bold text-gray-800 ${imageAvailable ? "bg-green-500" : "bg-red-500"} rounded-lg shadow-sm cursor-pointer grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={(e) => downloadCSV(e)}>
                    Download CSV {<MdFileDownload className='font-4xl' />}
                </motion.div>
                {(networkType === "lncinteractions_network") && <motion.div whileHover={{ scale: 1.05 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`flex items-center text-center justify-center h-20 px-3 py-2 sm:mx-10 text-xl font-bold text-gray-800 ${imageAvailable ? "bg-green-500" : "bg-red-500"} rounded-lg shadow-sm cursor-pointer grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={(e) => downloadAllLNC(e)} >
                    Download All lncRNA-Interactions {<MdFileDownload className='font-4xl' />}
                </motion.div>}
                <motion.div whileHover={{ scale: 1.05 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                }} className={`flex items-center text-center justify-center h-20 px-3 py-2 sm:mx-10 text-xl font-bold text-gray-800 ${imageAvailable ? "bg-green-500" : "bg-red-500"} rounded-lg shadow-sm cursor-pointer grow shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`} onClick={(e) => downloadImage(e)} >
                    Download Image {<MdFileDownload className='font-4xl' />}
                </motion.div>

            </div>


        </div>
    )
}


export default NetworkPage