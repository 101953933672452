import { createSlice } from "@reduxjs/toolkit";


const selectedSlice = createSlice({
    name :"selectionname",
    initialState: {
        name : "",
    },
    reducers:{
        changename(state,action){
            state.name = action.payload
        }

    }
}) 

export const selectedActions = selectedSlice.actions;

export default selectedSlice




// import { createSlice } from "@reduxjs/toolkit";


// const selectedSectionSlice = createSlice({
//     name :"selectedsect",
//     initialState: {
//         selection : "",
//     },
//     reducers:{
//         changename(state,action){
//             state.selection = action.payload
//         }

//     }
// }) 

// export const selectedSectionActions = selectedSectionSlice.actions;

// export default selectedSectionSlice