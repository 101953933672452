import React from "react";
import {useEffect} from "react";
import Axios from 'axios'
import { useDispatch } from "react-redux";
// import { Navigate } from "react-router-dom";

import { geneListActions } from "../store/geneListSlice";
// import GeneRedux from "../components/geneReduxOLD";
import Landing from "../components/landing";
// import GeneDetails from "../components/geneDetails";
// import DetailedAnalysis from "../components/detailedanalysis";

function ReduxHome(){

    const dispatch = useDispatch()

    // const selectedSection = useSelector((state)=> state.selectionname.name)

    useEffect(()=>{

        Axios.get(`${process.env.REACT_APP_API}/data`).then((response)=>{

            dispatch(geneListActions.creategenenamelist(response.data))
            // console.log(`this is not working ${response.data}`)
        });
    },[]);

    return(
        <div className="items-center justify-center min-h-screen dark:bg-gray-600">

            <div className="flex w-screen bg-gray-200 dark:bg-gray-800">
            {<Landing/>}
            </div>

        </div>
    )

}

export default ReduxHome