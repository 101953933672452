// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import './App.css'
import React, { useEffect } from "react";
import { createRoot } from 'react-dom/client';
import "./css/index.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import App from "./App";
import { Provider } from 'react-redux'
import { store, persist_store } from "./store/store";
import About from "./pages/about";
import Network from "./pages/network";
import Contact from "./pages/contact";
import GeneDetails from './components/geneDetails';
import DetailedAnalysis from './components/detailedanalysis';
import { PersistGate } from 'redux-persist/integration/react'
// import Navbar from './components/Navbar';




function AppWithCallbackAfterRender() {
  useEffect(() => {
    // console.log('rendered');

  });

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Provider store={store}><PersistGate persistor={persist_store}><App /></PersistGate></Provider>} />
        <Route path="/selection" element={<Provider store={store}><PersistGate persistor={persist_store}><GeneDetails /></PersistGate></Provider>} />
        <Route path="/results" element={<Provider store={store}><PersistGate persistor={persist_store}><DetailedAnalysis /></PersistGate></Provider>} />
        <Route path="/about" element={<Provider store={store}><PersistGate persistor={persist_store}><About /></PersistGate></Provider>} />
        <Route path="/network" element={<Provider store={store}><PersistGate persistor={persist_store}><Network /></PersistGate> </Provider>} />
        <Route path="/pathway" element={<Provider store={store}><PersistGate persistor={persist_store}><Network /></PersistGate> </Provider>} />
        <Route path="/contact" element={<Provider store={store}><PersistGate persistor={persist_store}><Contact /></PersistGate> </Provider>} />
      </Routes>
    </BrowserRouter>

  )
}



const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />)
