import React from 'react';
import Footer from './components/footer';
import Navbar from "./components/Navbar";


// import { SelectedName } from './components/nameViewer';
// import { PostsList } from './components/PostsList';
// import { AddPostFrom } from './features/gene/AddPostForm';
// import {SinglePostPage} from "./features/gene/SinglePostPage"

// import Home from './pages/home'

import ReduxHome from './pages/home_redux';
function App() {
  // console.log("App running")
  return (
    <div className=''>

      {/* <Home/> */}
      <div className="fixed z-50 flex w-screen mb-10 bg-white/30 dark:bg-black/30 flex-nowrap drop-shadow-lg backdrop-blur-sm dark:backdrop-blur-sm">
        <Navbar />
      </div>
      <div className='flex w-screen'>
      <ReduxHome />
      </div>
    
      <div className='fixed bottom-0 w-screen'>
        <Footer/>
      </div>

      {/* <SearchBar/> */}

    </div>
  );
}



export default App;
