import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import Select from 'react-select'
import Axios from 'axios'
import { geneNameActions } from "../store/geneNameSlice";
// import { geneListActions } from "../store/geneListSlice";
import {selectedActions} from "../store/selectedSection"
import {promoterNameActions} from "../store/promoterNameSlice"
import {snpNameActions} from "../store/snpNameSlice"
import {variantNameActions} from "../store/varientNameSlice"
import {typeNameActions} from "../store/typeNameSlice"
import {useNavigate} from 'react-router-dom'
// import {useSelector} from "react-redux"

function SearchBar() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [beginData,setBeginData] = useState([]);

  useEffect( ()=>{
      Axios.get(`${process.env.REACT_APP_API}/data`).then((resp)=>{
          // console.log(resp.data)
          setBeginData(resp.data);
      });
  },[]);
  
  const [selected, setSelected] = useState("");

  const handleChange = (event) => {
    // console.log(`This is the home_redux page gene name ${event.value}`)
    dispatch(geneNameActions.changename(event.value))
    dispatch(selectedActions.changename("gene"))
    dispatch(promoterNameActions.changename(""))
    dispatch(snpNameActions.changename(""))
    dispatch(variantNameActions.changename(""))
    dispatch(typeNameActions.changename(""))
    
    
    // console.log(`the test ${event}`)
    setSelected(event.value);
    navigate("/selection")
    // console.log(event)
  }
  // const handleSubmit = (event) =>{
  //     selectTheName(selected);

  // }
  // console.log(beginData)
 const Options = beginData.map(d=>({
   "value":d.gene_name,
   "label": d.gene_name
 }))

  return (

    <div className='content-center justify-center w-full align-middle dark:text-black text-balck'>
      <div>
        <Select onChange={handleChange} options={Options} >

          
          {beginData.map(option => {
            
            
            <option key={option.value} value={option.value}>
              {option.value}
              {/* {console.log(option)} */}
              
            </option>


          
          })}

        </Select>
        {/* {console.log(selected)} */}
      </div>
      {/* <div>
        {selected}
      </div> */}
    </div>




  )

}

export default SearchBar;