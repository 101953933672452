import React from "react";
import { useEffect, useState } from "react";
import Axios from 'axios'
import { motion } from "framer-motion";
import VariantRedux from "./variantRedux";
import { useDispatch, useSelector } from "react-redux"
// import { variantListActions } from "../store/variantListSlice";
// import { variantNameActions } from "../store/varientNameSlice";
// import { geneNameActions } from "../store/geneNameSlice"
import { typeListActions } from "../store/typeListSlice"
import { typeNameActions } from "../store/typeNameSlice"
import ComparePage from "./compare";
import Navbar from "./Navbar";
function DetailedAnalysis() {


    const dispatch = useDispatch()
    const typeNameState = useSelector((state) => state.typename.name)
    const snpNameState = useSelector((state) => state.snpname.name)
    const geneNameState = useSelector((state) => state.genename.name)
    const promoterNameState = useSelector((state) => state.promotername.name)
    const typeData = useSelector((state) => state.typelist.list)
    const variantNameState = useSelector((state) => state.variantname.name)
    // console.log("Inside the VAriant page")


    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API}/data?gene_name=${geneNameState}&promoter_number=${promoterNameState}&snp_id=${snpNameState}&variant=${variantNameState}`).then((response) => {
            dispatch(typeListActions.createtypenamelist(response.data))
        });



    }, [geneNameState, snpNameState, variantNameState]);

    const [compstate, setCompstate] = useState("single");

    let image_to_show
    if (typeNameState !== "" && compstate === "single") {
        image_to_show = <VariantRedux />
        // console.log("This is variant page")
    } else if (compstate === "compare") {
        image_to_show = <ComparePage />
    }

    const setCompare = () => {
        setCompstate("compare")
    }

    return (
        <div className="">
            <div className="fixed z-50 flex w-screen mb-10 bg-white/30 dark:bg-black/30 flex-nowrap drop-shadow-lg backdrop-blur-sm dark:backdrop-blur-sm">
                <Navbar />
            </div>
            <div className="flex flex-col justify-center w-screen min-h-screen pt-10 pb-20 bg-white sm:pt-40 md:py-10 dark:bg-black md:mt-0 md:pt-40 md:pb-20">

                <div className="flex flex-col px-4 py-5 bg-gray-300 sm:rounded-lg sm:mx-10 sm:flex-row dark:bg-gray-800">
                    <div className="flex flex-col text-center sm:gap-3 sm:flex-row">
                        <h1 className="text-xl font-bold text-gray-600 sm:text-3xl dark:text-gray-300">Gene :  <span className="text-red-800 uppercase dark:text-red-500">{geneNameState}</span> </h1>
                        <h1 className="text-xl font-bold text-gray-600 sm:text-3xl dark:text-gray-300">Promoter :<span className="text-red-800 uppercase dark:text-red-500">{promoterNameState} </span></h1>
                        <h1 className="text-xl font-bold text-gray-600 sm:text-3xl dark:text-gray-300">Snp id :<span className="text-red-800 dark:text-red-500">{snpNameState}</span> </h1>
                        <h1 className="text-xl text-3xl font-bold text-gray-600 dark:text-gray-300"> <span className="text-red-800 uppercase dark:text-red-500">{variantNameState}</span></h1>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-6 font-bold text-black cursor-pointer sm:mx-5 text-md sm:text-2xl sm:absolute right-10">
                        <a href={`https://www.ncbi.nlm.nih.gov/snp/${snpNameState}`} className="flex items-center justify-center py-1 bg-green-500 border border-black sm:px-4 grow rounded-xl hover:bg-gray-200">View in dbSNP</a>
                    </div>
                </div>

                <div className="bg-white md:grid md:grid-cols-3 dark:bg-black min-w-screen">

                    <div className="flex flex-col justify-center py-3 bg-white sm:col-span-1 dark:bg-black">

                        <h1 className="text-xl font-bold text-center bg-gray-200 sm:ml-10">Available Alleles</h1>
                        <div className="flex flex-row items-center justify-center pt-5 sm:flex-wrap sm:ml-10 sm:flex-col md:flex-row sm:pt-0">
                            {
                                typeData.map((val, key) => {
                                    console.log(typeData)

                                    const setTypeName = () => {
                                        console.log("Action Clicked Variant")
                                        dispatch(typeNameActions.changename(val.type))
                                        setCompstate("single")
                                    }
                                    return (<div className=" sm:justify-center sm:flex sm:grow sm:py-5">


                                        <motion.div whileHover={{ scale: 1.1 }} animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                                            type: "spring",
                                            damping: 10,
                                            mass: 0.75,
                                            stiffness: 100,
                                        }} onClick={setTypeName} className={`flex items-center justify-center sm:h-20 sm:px-3 mx-10 ${typeNameState === val.type ? "bg-gray-300" : 'bg-white'} rounded-lg shadow-sm cursor-pointer shadow-black/50 dark:shadow-white md:py-2 md:w-40 lg:w-40 hover:shadow-xl dark:bg-gray-800 lg:mx-0 dark:hover:text-green-500 dark:text-white dark:hover:shadow-green-500/50 dark:hover:shadow-xl`}>
                                            <ul className="flex flex-wrap px-5 py-2 text-4xl font-bold rounded-lg text-md ">{val.type}
                                            </ul>

                                        </motion.div>
                                    </div>)
                                })
                            }
                        </div>
                        <div className="flex justify-center px-4 py-4" onClick={setCompare}>
                            <div className="px-6 py-3 font-bold bg-white sm:ml-10 rounded-xl drop-shadow-xl hover:bg-green-800 hover:text-white dark:hover:bg-green-500">
                                Compare All
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center col-span-2 px-1 py-5 bg-white dark:bg-black">
                        {image_to_show}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DetailedAnalysis