import { createSlice } from "@reduxjs/toolkit";


const variantNameSlice = createSlice({
    name :"variantname",
    initialState: {
        name : "",
    },
    reducers:{
        changename(state,action){
            state.name = action.payload
        }

    }
}) 

export const variantNameActions = variantNameSlice.actions;

export default variantNameSlice