import React from "react";
import { useState, useEffect } from "react";
import Axios from 'axios'
import { useDispatch, useSelector } from "react-redux"
import { motion } from 'framer-motion';
import { promoterListActions } from "../store/promoterListSlice";
import { promoterNameActions } from "../store/promoterNameSlice";
// import { geneNameActions } from "../store/geneNameSlice";
import CourosalSNPs from "./snpcourosal"
import VariantOptions from "./variantoptions";
import { RiFileWord2Fill, RiFileExcel2Fill } from "react-icons/ri"
import fileDownload from 'js-file-download';
import { MdFileDownload } from 'react-icons/md'
import Navbar from "./Navbar";



function GeneDetails() {
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    }
    const downloadXcel = (e) => {
        e.preventDefault()
        Axios({
            url: `${process.env.REACT_APP_API}/download?location=data&type=excel&file=${geneNameState}_CGIs.xlsx`,
            method: "GET",
            responseType: 'blob'
        }).then((res) => {
            fileDownload(res.data, `${geneNameState}.xlsx`)
        })
    }
    const downloadDoc = (e) => {
        e.preventDefault()
        Axios({
            url: `${process.env.REACT_APP_API}/download?location=data&type=word&file=${geneNameState}_CGIs.docx`,
            method: "GET",
            responseType: 'blob'
        }).then((res) => {
            fileDownload(res.data, `${geneNameState}.docx`)
        })
    }

    const [description, setDescription] = useState([])

    const dispatch = useDispatch()
    const promoterNameState = useSelector((state) => state.promotername.name)
    const geneNameState = useSelector((state) => state.genename.name)
    const snpNameState = useSelector((state) => state.snpname.name)
    const promoterData = useSelector((state) => state.promoterlist.list)


    // console.log(`The gene name is ${geneNameState}`)


    useEffect(() => {

        Axios.get(`${process.env.REACT_APP_API}/data?gene_name=${geneNameState}`).then((response) => {
            // console.log("called the api")

            dispatch(promoterListActions.createpromoternamelist(response.data))
        });
        Axios.get(`${process.env.REACT_APP_API}/description?Gene=${geneNameState}`).then((response) => {
            // console.log("CALLING THE BIOTYPE")
            setDescription(response.data)
            // console.log(description)
        });

    }, [geneNameState]);

    let snp_page
    if (promoterNameState !== "") {
        snp_page = <CourosalSNPs />
        // console.log(`the snp is ${promoterNameState}`)
    }
    let variant_page
    if (snpNameState !== "") {
        variant_page = <VariantOptions />
        // console.log(`the snp is ${snpNameState}`)
    }

    useEffect(() => {
        scrollToBottom()
    }, [snp_page, variant_page])
    return (
        <div className="">
            <div className="absolute top-0 z-50 flex w-screen mb-10 bg-white/30 dark:bg-black/30 flex-nowrap drop-shadow-lg backdrop-blur-sm dark:backdrop-blur-sm">
                <Navbar />
            </div>
            <div className="flex flex-col w-screen py-10 mt-10 mb-10 bg-white md:py-10 dark:bg-black md:mt-0" >
                {/* <div className="pl-4 mx-10">Back</div> */}
                <div className="flex flex-col items-center mt-10 text-5xl font-bold text-white bg-gray-800 rounded-lg sm:mx-10 sm:pl-4 sm:py-2 sm:flex-row">
                    {geneNameState}
                    <div className="flex flex-row items-center justify-center gap-6 py-3 mx-5 text-2xl text-black cursor-pointer sm:py-0 sm:absolute right-10 ">
                        <div className="flex flex-row items-center px-2 py-1 text-sm bg-green-500 sm:text-lg grow rounded-xl hover:bg-gray-200" onClick={(e) => downloadDoc(e)}>{<MdFileDownload />}  Sequence {<RiFileWord2Fill className="ml-2 font-bold" />}</div>
                        <div className="flex flex-row items-center px-2 py-1 text-sm bg-green-500 sm:text-lg grow rounded-xl hover:bg-gray-200" onClick={(e) => downloadXcel(e)}>{<MdFileDownload />}  SNPs in CGIs {<RiFileExcel2Fill className="ml-2 font-bold" />}</div>
                    </div>
                </div>
                <div className="px-4 py-4 mx-2 my-4 bg-gray-300 rounded-lg sm:mx-10 dark:bg-black dark:border">


                    {
                        description && description.map(descrip => {
                            return (
                                <div className="flex flex-col">
                                    <div className="flex flex-col justify-around pt-2 pb-5 font-bold sm:flex-row">
                                        <a href={`https://www.ncbi.nlm.nih.gov/gene/${descrip.Entrez_ID}`} className="px-6 py-2 my-2 border cursor-pointer sm:my-0 dark:text-white rounded-xl hover:bg-gray-800 hover:text-white">
                                            Entrez ID : {descrip.Entrez_ID}
                                        </a>
                                        <a href={`http://asia.ensembl.org/Homo_sapiens/Gene/Summary?g=${descrip.Ensembl_ID}`} className="px-6 py-2 my-2 border cursor-pointer sm:my-0 dark:text-white rounded-xl hover:bg-gray-800 hover:text-white">
                                            Ensembl ID : {descrip.Ensembl_ID}
                                        </a>
                                        <a href={`https://www.genenames.org/tools/search/#!/?query=${descrip.HGNC_ID}`} className="px-6 py-2 my-2 border cursor-pointer sm:my-0 dark:text-white rounded-xl hover:bg-gray-800 hover:text-white">
                                            HGNC ID : {descrip.HGNC_ID}
                                        </a>
                                    </div>

                                    <div className="px-2 py-2 bg-gray-200 rounded-lg dark:bg-gray-800">
                                        <div className="px-2 py-1 rounded-lg ">
                                            <h1 className="font-bold dark:text-white">
                                                Biotype:
                                            </h1>
                                            <p className="px-4 py-1 bg-gray-300 rounded-lg">
                                                {descrip.Biotype}
                                            </p>
                                        </div>
                                        <div className="px-2 py-1 rounded-lg ">
                                            <h1 className="font-bold dark:text-white">
                                                Chromosome Location:
                                            </h1>
                                            <p className="px-4 py-1 bg-gray-300 rounded-lg ">
                                                {descrip.Chromosome_Location}
                                            </p>
                                        </div>
                                        <div className="px-2 py-1 rounded-lg ">
                                            <h1 className="font-bold dark:text-white">
                                                Description:
                                            </h1>
                                            <p className="px-4 py-1 bg-gray-300 rounded-lg ">
                                                {descrip.Gene_Description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
                <div className="flex justify-center pt-10 text-2xl font-bold text-gray-600 dark:text-gray-200">
                    Available Promoters
                </div>
                <div className="flex flex-col items-center justify-center py-3 lg:flex-row">
                    {/* {console.log(promoterData)}
                {console.log("Inside the gene page")} */}

                    {
                        promoterData.map((val, key) => {
                            // console.log("Entered into the map ")


                            const setPromoterName = () => {
                                // console.log("Action Clicked")
                                dispatch(promoterNameActions.changename(val.promoter_number))
                            }

                            return (<div className="px-5 py-2">


                                <motion.div whileHover={{ scale: 1.2 }} initial={{ scale: 0.3, opacity: 0, y: 60 }}
                                    animate={{ scale: 1, opacity: 1, y: 0 }} transition={{
                                        type: "spring",
                                        damping: 10,
                                        mass: 0.75,
                                        stiffness: 100,
                                    }} onClick={setPromoterName} className={`flex items-center justify-center w-40 h-20 px-3 py-2 mx-10 text-gray-800 ${promoterNameState === val.promoter_number ? "bg-gray-300 font-bold" : "bg-white"} rounded-lg shadow-sm cursor-pointer shadow-black lg:w-40 hover:shadow-xl lg:mx-0 hover:text-black dark:hover:shadow-green-500/50 dark:hover:shadow-xl dark:bg-gray-800 dark:text-gray-200 dark:hover:text-green-500`}>
                                    {console.log()}
                                    <ul className="flex flex-wrap px-5 py-5 font-bold rounded-lg text-md ">Promoter {val.promoter_number}


                                    </ul>

                                </motion.div>
                            </div>)
                        })
                    }
                </div>
                <div>
                    {snp_page}
                </div>
                <div id="type">
                    {variant_page}
                </div>
            </div>
        </div>
    )

}

export default GeneDetails